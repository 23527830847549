import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAuth } from "../../AuthProvider";
import { APIClient } from "../../utils/services";
import { useLocation, useNavigate } from "react-router-dom";
import { MAX_AUDIO_LENGTH, setSelected } from "../../store/audioAssetReducer";
import { useDispatch } from "react-redux";
import { setTempAudio } from "../../store/generateVideoReducer";
import * as hooks from "../../store/hooks";
import mixpanel from "mixpanel-browser";

const MAX = MAX_AUDIO_LENGTH;
const initialState = { start: 0, end: MAX };
const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
mixpanel.init(MIXPANEL_TOKEN);

export default function useAudioTrim() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [audioKey, setAudioKey] = useState(0);
  const [showToastLimitDurationAudio, setShowToastLimitDurationAudio] =
    useState(false);

  const [file, setFile] = useState<{
    file: File;
    url?: string;
    name?: string[];
  }>({
    file: new File([], ""),
  });

  const [newFiles, setNewFiles] = useState<
    { file: File; name: string; url: string }[]
  >([]);

  const [mirt, setMirt] = useState(initialState);
  const { delta, setAudioDuration } = hooks.useAudioTrim();
  const location = useLocation();

  const navigationState = location.state || {};
  const { files: fileFromNavigation } = navigationState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  const onWaveformLoaded = () => setIsLoading(false);

  const isLatinOnly = (name: string) => /^[A-Za-z0-9\s.,'()-]+$/.test(name);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsLoading(false);
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      if (isLatinOnly(selectedFile.name)) {
        setFile({
          file: selectedFile,
          url: URL.createObjectURL(selectedFile),
        });
      } else {
        toast.error(
          "The file name contains non-Latin characters. Please upload a file with a Latin character name.",
          { duration: 5000 }
        );
        navigate("/app/dashboard");
      }
    } else {
      console.error("No file selected");
    }
  }

  useEffect(() => {
    if (newFiles.length > 0) {
      navigate("/app/create-video/trim", {
        state: { from: "generate", files: newFiles },
      });
    }
  }, [newFiles, navigate]);

  const handleChangeLibrary = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(false);

    const selectedFiles = event.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const filesArray: File[] = Array.from(selectedFiles);

      const isLatinOnly = (name: string) => /^[A-Za-z0-9\s.,'()-]+$/.test(name);

      const validFiles = filesArray.filter((file) => isLatinOnly(file.name));
      const invalidFiles = filesArray.filter((file) => !isLatinOnly(file.name));

      if (invalidFiles.length > 0) {
        if (filesArray.length === 1) {
          toast.error(
            "The file name contains non-Latin characters. Please upload a file with a Latin character name.",
            { duration: 5000 }
          );
        } else {
          toast.error(
            "Some files contain non-Latin characters. Only files with correct names will be processed.",
            { duration: 5000 }
          );
        }
      }

      if (validFiles.length > 0) {
        const extractedFiles = validFiles.map((file) => ({
          file: file,
          name: file.name,
          url: URL.createObjectURL(file),
        }));
        setNewFiles(extractedFiles);
      } else if (filesArray.length === 0) {
        console.error("No files found");
      }
    } else {
      console.error("Files not found");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsLoading(false);

    const isLatinOnly = (name: string) => /^[A-Za-z0-9\s.,'()-]+$/.test(name);

    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files?.length > 0
    ) {
      const selectedFile = e.dataTransfer.files[0];

      if (!isLatinOnly(selectedFile.name)) {
        toast.error(
          "The file name contains non-Latin characters. Please upload a file with a Latin character name.",
          { duration: 5000 }
        );
        navigate("/app/dashboard");
        return;
      }

      setFile({
        file: selectedFile,
        url: URL.createObjectURL(selectedFile),
      });
    } else {
      console.error("File not found");
    }
  };

  const handleDropLibrary = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsLoading(false);

    const isLatinOnly = (name: string) => /^[A-Za-z0-9\s.,'()-]+$/.test(name);

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles && droppedFiles.length > 0) {
      const filesArray: File[] = Array.from(droppedFiles);

      const validFiles = filesArray.filter((file) => isLatinOnly(file.name));
      const invalidFiles = filesArray.filter((file) => !isLatinOnly(file.name));

      if (invalidFiles.length > 0) {
        if (filesArray.length === 1) {
          toast.error(
            "The file name contains non-Latin characters. Please upload a file with a Latin character name.",
            { duration: 5000 }
          );
        } else {
          toast.error(
            "Some files contain non-Latin characters. Only files with correct names will be processed.",
            { duration: 5000 }
          );
        }
      }

      if (validFiles.length > 0) {
        const extractedFiles = validFiles.map((file) => ({
          file: file,
          name: file.name,
          url: URL.createObjectURL(file),
        }));

        setNewFiles(extractedFiles);
      } else if (filesArray.length === 0) {
        console.error("No files found");
      }
    } else {
      console.error("Files not found");
    }
  };

  const pausePath = `M12.404 2.364A2.364 2.364 0 0 0 10.041 0H6.134a2.364 2.364 0 0 0-2.363 2.364v27.273A2.364 2.364 0 0 0 6.134 32h3.907a2.364 2.364 0 0 0 2.363-2.363V2.364Zm15.826 0A2.364 2.364 0 0 0 25.866 0H21.96a2.364 2.364 0 0 0-2.364 2.364v27.273A2.364 2.364 0 0 0 21.96 32h3.906a2.364 2.364 0 0 0 2.364-2.363V2.364Z`;
  const pause = () =>
    document.querySelectorAll("path").forEach((i) => {
      if (i.getAttribute("d") === pausePath) {
        i.parentElement?.parentElement?.click();
      }
    });

  function send(start: any, end: any) {
    setIsLoading(true);

    if (!file.file || !file.file.name) {
      console.error("select a file");
      return;
    }

    const audioDurationInSeconds = Math.round(end) - Math.round(start);
    if (audioDurationInSeconds > 240) {
      setShowToastLimitDurationAudio(true);
      return;
    }

    const client = new APIClient();
    client.authenticate(
      undefined,
      undefined,
      process.env.REACT_APP_MUZE_API_KEY
    );

    setIsLoadingProgress(true);

    const callback = (url: string) => {
      axios
        .put(url, file.file, { headers: { "Content-Type": "audio/mpeg" } })
        .then(() => {
          const tempAudioReference = {
            name: file.file.name,
            key: `temp/${ownerId}/${file.file.name}`,
            delete_source: false,
            next_step_video: true,
          };

          const audioDurationReference = {
            start: Math.round(start),
            end: end > 240 ? 240 : Math.round(end),
          };

          dispatch(setTempAudio(tempAudioReference));
          dispatch(setAudioDuration(audioDurationReference));

          dispatch(
            setSelected({
              name: "",
              owner_id: "",
              AUDIO_status: "",
              asset_id: "",
              http_link: "",
              last_update: "",
              size: 0,
              auth_user: "",
              bucket: "",
              key: "",
              next_step_video: false,
              timestamp: "",
              "mypart-available": false,
              "stems-available": false,
              transcriptions: [],
              events: [],
              parts: [],
            })
          );

          navigate("/app/create-video/audio-reactivity");
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoadingProgress(false);
        });
    };
    client.get_signed_url(
      ownerId,
      file.file.name ? file.file.name : "",
      "audio",
      callback
    );
  }

  async function sendFilesByLibrary() {
    setIsLoading(true);
    const promises = [];

    for (const file of fileFromNavigation) {
      promises.push(sendFileToLibrary(file, delta));
    }

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Error sending files:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function sendFileToLibrary(file: any, delta: any) {
    return new Promise<void>((resolve, reject) => {
      if (!isLatinOnly(file.name)) {
        toast.error(
          "The file name contains non-Latin characters. Please upload a file with a Latin character name.",
          { duration: 5000 }
        );
        reject("Invalid file name.");
        return;
      }
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );

      toast.success("Sending music: " + file.name, {
        duration: 5000,
      });
      setIsLoadingProgress(true);

      const callback = (url: string) => {
        axios
          .put(url, file.file, {
            headers: {
              "Content-Type":
                file.file.type === "binary/octet-stream" &&
                file.file.name?.toLowerCase().endsWith(".wav")
                  ? "audio/wav"
                  : file.file.type || "audio/mpeg, audio/wav, audio/x-wav",
            },
          })
          .then(() => {
            const start = Math.round(delta.start);
            const stop = Math.round(delta.end);

            const audioDurationInSeconds = stop - start;

            if (audioDurationInSeconds > 240) {
              setShowToastLimitDurationAudio(true);
              setIsLoadingProgress(false);
              reject("Audio duration exceeds the 4 minutes limit");
              return;
            }

            const encodedOwnerId = encodeURIComponent(ownerId);
            const url = `${BASE_URL}/audio/upload/${encodedOwnerId}/?start=${start}&end=${stop}&file_key=temp/${encodedOwnerId}/${file.name}`;

            axios
              .get(url, { headers: { access_token: "abc123" } })
              .then((res) => {
                if (res?.data?.status && res.data.status === "ERROR") {
                  console.error(res.data.message);
                  toast.error(res.data.message, { duration: 5000 });
                  reject(res.data.message);
                } else {
                  pause();
                  mixpanel.track("Upload an audio", {
                    ownerId: ownerId,
                  });
                  navigate("/app/assets-library/songs");
                  resolve();
                }
                setIsLoadingProgress(false);
              })
              .catch((error) => {
                setIsLoadingProgress(false);
                reject("Failed to upload file: " + error.message);
              });
          })
          .catch((error) => {
            console.log(error);
            setIsLoadingProgress(false);
            setIsLoading(false);
            toast.error("Something went wrong, please try again", {
              duration: 5000,
            });
            reject("Failed to send file: " + error.message);
          });
      };

      client.get_signed_url(
        ownerId,
        file.name ? file.name : "",
        "audio",
        callback
      );
    });
  }

  const onChange = ({ start, end }: { start: number; end: number }) => {
    if (!isLoading) {
      //const adjustedEnd = Math.min(end, MAX * 1000);

      setMirt((old) => ({
        start,
        end, //Math.min(adjustedEnd, old.start + MAX * 1000),
      }));

      setAudioDuration({
        start: ~~(start / 1000),
        end: ~~(end / 1000),
      });
    }
  };

  const onChangeLibrary = ({ start, end }: { start: number; end: number }) => {
    if (file) {
      //const adjustedEnd = Math.min(end, MAX * 1000);

      setMirt((old) => ({
        start,
        end,
      }));

      setAudioDuration({
        start: ~~(start / 1000),
        end: ~~(end / 1000),
      });
    }
  };
  function formatSecondsAsTime(secs: number) {
    let hr = Math.floor(secs / 3600);
    let min: any = Math.floor((secs - hr * 3600) / 60);
    let sec: any = Math.floor(secs - hr * 3600 - min * 60);

    if (min < 10) {
      min = "0" + min;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }
    return min + ":" + sec;
  }

  return {
    send,
    sendFilesByLibrary,
    setShowToastLimitDurationAudio,
    showToastLimitDurationAudio,
    handleChange,
    onWaveformLoaded,
    onChange,
    formatSecondsAsTime,
    handleDragOver,
    handleDrop,
    handleChangeLibrary,
    handleDropLibrary,
    onChangeLibrary,
    setAudioDuration,
    delta,
    audioKey,
    isLoading,
    file,
    isLoadingProgress,
  };
}
