import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { APIClient } from "../../utils/services";
import { useAuth } from "../../AuthProvider";
import "./styles.css";

export default function PreviewModalSocialMedia(props: any) {
  const { shouldShow, title, url, onClose } = props;
  const [showAuthorizationModalFacebook, setShowAuthorizationModalFacebook] =
    useState(false);
  const [showAuthorizationModalInstagram, setShowAuthorizationModalInstagram] =
    useState(false);
  const [showAuthorizationModalTikTok, setShowAuthorizationModalTikTok] =
    useState(false);

  const ShareButton = async (urlVideo: string) => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      urlVideo
    )}`;
    window.open(url, "_blank");
  };

  const download = async (url: string, name: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = name + ".mp4";
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  };

  const handleShareVideoFacebook = async (url: string, videoName: string) => {
    setShowAuthorizationModalFacebook(true);
    onClose();
  };

  const handleShareVideoInstagram = async (url: string, videoName: string) => {
    setShowAuthorizationModalInstagram(true);
    onClose();
  };

  const handleShareVideoTiktok = async (url: string, videoName: string) => {
    setShowAuthorizationModalTikTok(true);
    onClose();
  };

  const confirmDownload = () => {
    setShowAuthorizationModalFacebook(false);
    setShowAuthorizationModalInstagram(false);
    setShowAuthorizationModalTikTok(false);
    download(url, title);
  };

  const cancelDownload = () => {
    setShowAuthorizationModalFacebook(false);
    setShowAuthorizationModalInstagram(false);
    setShowAuthorizationModalTikTok(false);
  };

  return (
    <>
      <Modal
        show={showAuthorizationModalFacebook}
        onHide={() => setShowAuthorizationModalFacebook(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Authorize Download ⬇️</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please authorize the download and share it making upload like a
            reels or stories on your Facebook Account.
          </p>
          <div className="justify-content-between">
            <button
              className="btn btn-primary"
              style={{
                color: "white",
                backgroundColor: "#FF30C4",
                borderColor: "white",
                marginRight: "10px",
              }}
              onClick={confirmDownload}
            >
              Confirm
            </button>
            <button
              className="btn btn-primary"
              style={{
                color: "white",
                backgroundColor: "black",
                borderColor: "white",
              }}
              onClick={cancelDownload}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAuthorizationModalInstagram}
        onHide={() => setShowAuthorizationModalInstagram(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Authorize Download ⬇️</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please authorize the download and share it making upload like a
            reels or stories on your Instagram Account.
          </p>
          <div className="justify-content-between">
            <button
              className="btn btn-primary"
              style={{
                color: "white",
                backgroundColor: "#FF30C4",
                borderColor: "white",
                marginRight: "10px",
              }}
              onClick={confirmDownload}
            >
              Confirm
            </button>
            <button
              className="btn btn-primary"
              style={{
                color: "white",
                backgroundColor: "black",
                borderColor: "white",
              }}
              onClick={cancelDownload}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAuthorizationModalTikTok}
        onHide={() => setShowAuthorizationModalTikTok(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Authorize Download ⬇️</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please authorize the download and share it making upload like a
            reels.
          </p>
          <div className="justify-content-between">
            <button
              className="btn btn-primary"
              style={{
                color: "white",
                backgroundColor: "#FF30C4",
                borderColor: "white",
                marginRight: "10px",
              }}
              onClick={confirmDownload}
            >
              Confirm
            </button>
            <button
              className="btn btn-primary"
              style={{
                color: "white",
                backgroundColor: "black",
                borderColor: "white",
              }}
              onClick={cancelDownload}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={shouldShow} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share your Video 🔥</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please select a social media platform to share your video:</p>
          <div className="share-buttons-overlay">
            <button
              onClick={() => handleShareVideoInstagram(url, title)}
              style={{
                border: "None",
                backgroundColor: "transparent",
                marginRight: "15px",
              }}
            >
              <i
                className="bi bi-instagram fa-instagram"
                style={{ fontSize: "34px", borderRadius: "10px" }}
              ></i>
            </button>
            <button
              onClick={() => handleShareVideoFacebook(url, title)}
              style={{
                border: "None",
                backgroundColor: "transparent",
                marginRight: "15px",
              }}
            >
              <i
                className="bi bi-facebook fa-facebook"
                style={{ fontSize: "34px", borderRadius: "10px" }}
              ></i>
            </button>
            <button
              onClick={() => handleShareVideoTiktok(url, title)}
              style={{
                border: "None",
                backgroundColor: "transparent",
                marginRight: "15px",
              }}
            >
              <i
                className="bi bi-tiktok"
                style={{ fontSize: "34px", borderRadius: "10px" }}
              ></i>
            </button>
            {/* <button
              onClick={() => handleShareVideo(url, title)}
              style={{ border: "None", backgroundColor: "transparent" }}
            >
              <i
                className="bi bi-youtube fa-youtube"
                style={{ fontSize: "34px", borderRadius: "10px" }}
              ></i>
            </button> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
